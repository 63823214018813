// 获取year, month, day, hours, minutes, seconds对应的epoch秒时间戳
export function getSecondsSinceEpoch(year, month, day, hours, minutes, seconds)
{
  const d = new Date(year, month - 1, day, hours, minutes, seconds);

  return Math.floor(d.getTime() / 1000);
}

// 获取当前epoch秒时间戳
export function getCurrentSecondsSinceEpoch()
{
  const d = new Date();

  return Math.floor(d.getTime() / 1000);
}

// 返回epoch秒时间戳对应的YYYY-MM-DD格式
export function timestampToYearMonthDay(timestampInSeconds)
{
  const d = new Date(timestampInSeconds * 1000);
  const year = String(d.getFullYear());
  const month = String(d.getMonth()+1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

// 返回epoch秒时间戳对应的MM-DD格式
export function timestampToMonthDay(timestampInSeconds)
{
  const d = new Date(timestampInSeconds * 1000);
  const month = String(d.getMonth()+1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${month}-${day}`;
}

// 返回epoch秒时间戳对应的HH:MM格式
export function timestampToHourMinute(timestampInSeconds)
{
  const d = new Date(timestampInSeconds * 1000);
  const hour = String(d.getHours()).padStart(2, '0');
  const minute = String(d.getMinutes()).padStart(2, '0');

  return `${hour}:${minute}`;
}

export function timestampToHourMinuteSecond(timestampInSeconds)
{
  const d = new Date(timestampInSeconds * 1000);
  const hour = String(d.getHours()).padStart(2, '0');
  const minute = String(d.getMinutes()).padStart(2, '0');
  const second = String(d.getSeconds()).padStart(2, '0');

  return `${hour}:${minute}:${second}`;
}

// 返回epoch秒时间戳对应周几
export function timestampToDayOfWeek(timestampInSeconds)
{
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const d = new Date(timestampInSeconds * 1000);
  const dayOfWeek = d.getDay();

  return daysOfWeek[dayOfWeek];
}

// 返回epoch秒时间戳对应的年月日十分信息对象
export function timestampToDateObj(timestampInSeconds)
{
  const d = new Date(timestampInSeconds * 1000);

  return {
    year   : d.getFullYear(),
    month  : d.getMonth() + 1,
    date   : d.getDate(),
    hour   : d.getHours(),
    minute : d.getMinutes() 
  }
}

// 获取当前日期, 格式MM-DD
export function getCurrentDate()
{
  const d = new Date();
  const month = String(d.getMonth()+1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');

  return `${month}-${day}`;
}

// 获取过去n天0时0分0秒对应的epoch秒时间戳
export function getPastNdayStartSecondsSinceEpoch(n)
{
  const d = new Date();
  var seconds = Math.floor(d.getTime() / 1000);
  seconds -= n * 86400;
  seconds -= (seconds % 86400);

  return seconds;
}

// 获取距离当前时间最近的下一个整点小时的时间信息, 包含年月日时分
export function getNextNearestHourTime()
{
  const d = new Date();
  var timeSeconds = d.getTime() / 1000;
  var newTimeSeconds = timeSeconds + 3600 - timeSeconds % 3600;
  var newDate = new Date(newTimeSeconds * 1000);

  return {
    year: newDate.getFullYear(),
    month: newDate.getMonth() + 1,
    date: newDate.getDate(),
    hour: newDate.getHours(),
    minute: newDate.getMinutes()
  }
}

// 获取当前时区, 例如 GMT+8:00
export function getTimeZone()
{
  const offset = new Date().getTimezoneOffset();
  const hour = Math.floor(Math.abs(offset) / 60);
  const minute = Math.abs(offset) % 60;
  const sign = offset <= 0 ? '+' : '-';

  return `GMT${sign}${hour}:${minute.toString().padStart(2, '0')}`;
}

// 获取当前时区, 例如返回值为 8 表示东八区, -7 表示西七区
export function getTimeZoneOffset()
{
  const offset = new Date().getTimezoneOffset();
  const hour = Math.floor(Math.abs(offset) / 60);

  return offset <= 0 ? hour : -hour;
}

export function getDaysInLastMonth()
{
  const now = new Date();
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);

  return lastDayOfLastMonth.getDate();
}