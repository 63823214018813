import React from "react";
import ReactTooltip from 'react-tooltip';
import jsCookie from "js-cookie";
import { message, DatePicker, TimePicker, ConfigProvider, Popover, Modal, Spin } from "antd";
import { withAuth0 } from "@auth0/auth0-react";
import { Auth0Client } from '@auth0/auth0-spa-js';
import iconNewMeeting from './icon_new_meeting.svg';
import iconRecaps from './icon_recaps.svg';
import iconRecapsImMessage from './icon_recaps_im_message.svg';
import iconRecapsTranscriptText from './icon_recaps_transcript_text.svg';
import iconRecapsTranslateText from './icon_recaps_translate_text.svg';
import iconJoinMeeting from './icon_join_meeting.svg';
import iconScheduleMeeting from './icon_schedule_meeting.svg';
import iconLink from './icon_link.svg';
import iconCopy from './icon_copy.svg';
import iconMicOn from './icon_mic_on.svg';
import iconMicOff from './icon_mic_off.svg';
import iconWebcamOn from './icon_webcam_on.svg';
import iconWebcamOff from './icon_webcam_off.svg';
import iconSettings from './icon_settings.svg';
import iconLogout from './icon_logout.svg';
import iconUserSurvey from './icon_user_survey.svg';
import iconProStar from './icon_pro_star.svg';
import iconRest from './icon_rest.svg';
import iconHistoryRecord from './icon_history_record.svg';
import aimeetpng from './aimeet.png';
import dayjs from 'dayjs';
import * as TimeUtils from './time_utils';
import * as DomainUtils from './domain_utils';

import './MainPage.css';

const ID_TOKEN_COOKIE = "id_token";
const USER_SETTING_COOKIE = "openvp.userSetting";

const ToolkitFunction = {
  MAIN_PAGE_MEETING : 'MainPage Meeting',
  NEW_MEETING       : 'New Meeting',
  JOIN_MEETING      : 'Join Meeting',
  SCHEDULE_MEETING  : 'Schedule Meeting',
  MAIN_PAGE_RECAPS  : 'MainPage Recaps'
}

const MicStatus = {
  MicON  : "Mic ON",
  MicOFF : "Mic OFF"
}

const VideoStatus = {
  VideoON  : 'Video ON',
  VideoOFF : 'Video OFF'
}

const MeetingInfoFunction = {
  Upcoming : 'Upcoming',
  History  : 'History'
}

const WWW_URL = 'https://aimeet.cc';    // https://aimeet.cc | http://localhost:3002

class MainPage extends React.Component
{
  constructor()
  {
    super();

    let idToken = jsCookie.getJSON(ID_TOKEN_COOKIE);
    if (!idToken) {
      window.location.replace(WWW_URL);
      return;
    }

    this.auth0 = new Auth0Client({
      domain: 'dev-sm2tmwdyfa1mft4z.us.auth0.com',
      clientId: '2BtKWfo8B7asryY3lBpsE0kjdPUAUt3E',
      authorizationParams: {
          redirect_uri: window.location.origin,
      }
    });

    this.state = {
      curToolkitFunction: ToolkitFunction.MAIN_PAGE_MEETING,
      showUserProfileMenu: false,
      dateTime: '',
      micStatus: MicStatus.MicON,
      videoStatus: VideoStatus.VideoON,
      audioInputDevices: [],
      videoInputDevices: [],
      audioOutputDevices: [],
      audioInputSelectedIdx: 0,
      videoInputSelectedIdx: 0,
      audioOutputSelectedIdx: 0,
      meetingId: '',
      meetingTopic: `${idToken.name}'s Meeting`,
      login: true,
      isJoining: false,
      isScheduling: false,
      isLoadingUpcoming: false,
      isLoadingHistory: false,
      idToken: idToken,
      peerId: idToken.sub,
      displayName: idToken.name,
      email: idToken.email,
      picture: idToken.picture,
      rawToken: idToken.__raw,
      showChangeAccountInfo: false,
      upcomingMeetings: [],
      historyMeetings: [],
      scheduleYear: -1,
      scheduleMonth: -1,
      scheduleDate: -1,
      scheduleHour: 0,
      scheduleMinute: 0,
      scheduleDurationHour: 1,
      scheduleDurationMinute: 0,
      scheduleTimezoneOffset: TimeUtils.getTimeZoneOffset(),
      showInviteModal: false,
      inviteRoomId: '',
      inviteTopic: '',
      inviteCreatorName: '',
      inviteStartTime: 0,
      inviteEndTime: 0,
      isPro: undefined,
      proExpTime: 0,
      showProSubscription: false,
      meetingInfoFunction: MeetingInfoFunction.Upcoming,
      isHoverMeetingInfoUpcomingFunction: false,
      isHoverMeetingInfoHistoryFunction: false,
      historyStartYear: -1,
      historyStartMonth: -1,
      historyStartDate: -1,
      historyEndYear: -1,
      historyEndMonth: -1,
      historyEndDate: -1,
      // recaps
      recapsMeetingTopic: '',
      recapsMeetingStartTime: 0,
      showRecapsImMessage: false,
      recapsImMessages: [],
      showRecapsTranscriptText: false,
      recapsTranscriptTexts: [],
      showRecapsTranslateText: false,
      recapsTranslateTexts: []
    }

    this.userProfileMenuStyle = {
      position: "absolute",
      width: "220px",
      top: "70px",
      right: "30px",
      backgroundColor: "#FFFFFF",
      border: "solid 1px #e5e5e5",
      borderRadius: "8px",
      visibility: "hidden",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 10,
    }

    this.enterMeetingSettingJoinButtonStyle = {
      width: "100%",
      height: "50px",
      cursor: "pointer",
      fontWeight: "500",
      fontSize: 22,
      borderRadius: "10px",
      backgroundColor: "#1a73e8",
      color: "white",
      border: "1px solid #f6f6f6"
    }

    this.scheduleMeetingSettingScheduleButtonStyle = {
      width: "100%",
      height: "50px",
      cursor: "pointer",
      fontWeight: "500",
      fontSize: 22,
      borderRadius: "10px",
      backgroundColor: "#1a73e8",
      color: "white",
      border: "1px solid #f6f6f6"
    }

    this.meetingFunctionUpcomingTextStyle = {
      width: "130px",
      height: "50px",
      lineHeight: "50px",
      textAlign: "center",
      cursor: "pointer",
      fontSize: "22px",
      fontWeight: "500",
      marginLeft: "30px",
      borderRadius: "10px"
    }

    this.meetingFunctionHistoryTextStyle = {
      width: "100px",
      height: "50px",
      lineHeight: "50px",
      textAlign: "center",
      cursor: "pointer",
      fontSize: "22px",
      fontWeight: "500",
      marginLeft: "30px",
      borderRadius: "10px"
    }
  }

  updateDateTime()
  {
    const now = new Date();
    const month = String(now.getMonth()+1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    this.setState({ dateTime: `${hour}:${minute} ${month}-${day} ${days[now.getDay()]}` });
  }

  getUpcomingMeetings()
  {
    fetch('https://signal.aimeet.cc/api/get_peer_upcoming_rooms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*'
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data && data.data) {
        this.setState({ upcomingMeetings: data.data.rooms });
      }
    })
    .catch(error => {
      console.log('fetch get_peer_upcoming_rooms error', error);
    }).finally(() => {
      this.setState({ isLoadingUpcoming: false });
    });
    this.setState({ isLoadingUpcoming: true });
  }

  getHistoryMeetings(startTs, endTs)
  {
    fetch('https://signal.aimeet.cc/api/get_peer_history_rooms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*'
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        startTs,
        endTs
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data && data.data) {
        this.setState({ historyMeetings: data.data.rooms });
      }
    })
    .catch(error => {
      console.log('fetch get_peer_history_rooms error', error);
    }).finally(() => {
      this.setState({ isLoadingHistory: false });
    });
    this.setState({ isLoadingHistory: true });
  }

  promptJoinUpcomingMeeting(roomId)
  {
    fetch('https://signal.aimeet.cc/api/get_latest_arrange_room_info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*'
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        roomId,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data && data.data) {
        if (data.data.acceptedPeerIds && data.data.acceptedPeerIds.includes(this.state.peerId))
          return;

        this.setState({
          showInviteModal: true,
          inviteRoomId: roomId,
          inviteTopic: data.data.topic,
          inviteCreatorName: data.data.creatorName,
          inviteStartTime: data.data.startTime,
          inviteEndTime: data.data.endTime
        });
      }
    })
    .catch(error => {
      console.log('fetch get_latest_arrange_room_info error', error);
    });
  }

  acceptMeetingInvitation(roomId, startTime, endTime)
  {
    fetch('https://signal.aimeet.cc/api/add_peer_upcoming_room', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*'
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        roomId,
        startTime,
        endTime
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data && data.data && data.data.success) {
        message.info('Accept success');
        this.getUpcomingMeetings();
      }
    })
    .catch(error => {
      console.log('fetch add_peer_upcoming_room error', error);
    });
  }

  scheduleMeeting()
  {
    if (this.state.scheduleYear === -1 || this.state.scheduleMonth === -1 || this.state.scheduleDate === -1) {
      message.info('Please check the meeting date');
      return;
    }
    if (this.state.scheduleHour === -1 || this.state.scheduleMinute === -1) {
      message.info('Please check the meeting time');
      return;
    }
    if (this.state.scheduleDurationHour === 0 && this.state.scheduleDurationMinute === 0) {
      message.info('Meeting duration is invalid');
      return;
    }

    let startTimeSeconds = TimeUtils.getSecondsSinceEpoch(this.state.scheduleYear, this.state.scheduleMonth, this.state.scheduleDate, this.state.scheduleHour, this.state.scheduleMinute, 0);
    if (TimeUtils.getTimeZoneOffset() !== this.state.scheduleTimezoneOffset)
    {
      startTimeSeconds += (TimeUtils.getTimeZoneOffset() - this.state.scheduleTimezoneOffset) * 3600;
    }
    let endTimeSeconds = startTimeSeconds + this.state.scheduleDurationHour * 3600+this.state.scheduleDurationMinute * 60;

    fetch('https://signal.aimeet.cc/api/arrange_room', {
      method: 'POST',
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        topic: this.state.meetingTopic,
        username: this.state.displayName,
        startTime: startTimeSeconds,
        endTime: endTimeSeconds
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.data && data.data.roomId) {
        message.info('Schedule meeting success');
        setTimeout(() => {
          this.getUpcomingMeetings();
          this.setState({ curToolkitFunction: ToolkitFunction.MAIN_PAGE_MEETING });
          this.setState({ isScheduling: false });
        }, 2000);
      } else {
        message.error('Server error, please try again');
        this.setState({ isScheduling: false });
      }
    })
    .catch(error => {
      message.error('Network error, please try again');
      this.setState({ isScheduling: false });
    });
    this.setState({ isScheduling: true });
  }

  cancelScheduleMeeting(roomId, startTime, endTime)
  {
    fetch('https://signal.aimeet.cc/api/cancel_arrange_room', {
      method: 'POST',
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        roomId,
        startTime,
        endTime,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.data && data.data.success) {
        message.info('Cancel schedule meeting success');
        this.getUpcomingMeetings();
      } else {
        message.error('Server error, please try again');
      }
    })
    .catch(error => {
      message.error('Network error, please try again');
    });
  }

  getUserInfo()
  {
    fetch('https://signal.aimeet.cc/api/get_user_info', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.data && data.data.proExpTime) {
        const timestampInSeconds = Math.floor(Date.now() / 1000);

        this.setState({ isPro: timestampInSeconds < data.data.proExpTime, proExpTime: data.data.proExpTime });
      }
    })
    .catch(error => {
      console.log('fetch get_user_info error', error);
    });
  }

  getRoomImMessage(roomId, roomCreateTime, startTime, endTime)
  {
    fetch('https://signal.aimeet.cc/api/get_room_im_messages', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        roomId,
        roomCreateTime,
        startTime,
        endTime,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.data) {
        this.setState({ recapsImMessages: data.data.imMessages });
      }
    })
    .catch(error => {
      console.log('fetch get_room_im_messages error', error);
    });
  }

  getRoomTranscriptText(roomId, roomCreateTime, startTime, endTime)
  {
    fetch('https://signal.aimeet.cc/api/get_room_transcript_texts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        roomId,
        roomCreateTime,
        startTime,
        endTime,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.data) {
        this.setState({ recapsTranscriptTexts: data.data.transcriptTexts });
      }
    })
    .catch(error => {
      console.log('fetch get_room_im_messages error', error);
    });
  }

  getRoomTranslateText(roomId, roomCreateTime, startTime, endTime)
  {
    fetch('https://signal.aimeet.cc/api/get_room_translate_texts', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appId: 3,
        token: this.state.rawToken,
        roomId,
        roomCreateTime,
        startTime,
        endTime,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok', response.statusText);
      }
      return response.json();
    })
    .then(data => {
      if (data.data) {
        this.setState({ recapsTranslateTexts: data.data.translateTexts });
      }
    })
    .catch(error => {
      console.log('fetch get_room_im_messages error', error);
    });
  }

  enumerateDevices()
  {
    navigator.mediaDevices.enumerateDevices()
      .then((deviceInfos) => {
        let audioInputDevices = [];
        let videoInputDevices = [];
        let audioOutputDevices = [];

        if (deviceInfos.length > 0 && deviceInfos[0].label === '') {
          navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(() => {
              return navigator.mediaDevices.enumerateDevices();
            })
            .then((deviceInfos) => {
              deviceInfos.forEach(deviceInfo =>
              {
                let canPush = true;

                if (deviceInfo.kind === "audioinput")
                {
                  for (let j = 0;j < audioInputDevices.length;++j)
                  {
                    if (audioInputDevices[j].groupId === deviceInfo.groupId)
                    {
                      canPush = false;
                      break;
                    }
                  }
                  if (canPush)
                    audioInputDevices.push(deviceInfo);
                }
                else if (deviceInfo.kind === "videoinput")
                {
                  for (let j = 0;j < videoInputDevices.length;++j)
                  {
                    if (videoInputDevices[j].groupId === deviceInfo.groupId)
                    {
                      canPush = false;
                      break;
                    }
                  }
                  if (canPush)
                    videoInputDevices.push(deviceInfo);
                }
                else if (deviceInfo.kind === 'audiooutput')
                {
                  for (let j = 0;j < audioOutputDevices.length;++j)
                  {
                    if (audioOutputDevices[j].groupId === deviceInfo.groupId)
                    {
                      canPush = false;
                      break;
                    }
                  }
                  if (canPush)
                    audioOutputDevices.push(deviceInfo);
                }
              });
              this.setState({ audioInputDevices, videoInputDevices, audioOutputDevices });
            })
            .catch(err => {
              console.log(`getUserMedia failed, err: ${err}`);
            });
        }
        else
        {
          deviceInfos.forEach(deviceInfo =>
          {
            let canPush = true;

            if (deviceInfo.kind === "audioinput")
            {
              for (let j = 0;j < audioInputDevices.length;++j)
              {
                if (audioInputDevices[j].groupId === deviceInfo.groupId)
                {
                  canPush = false;
                  break;
                }
              }
              if (canPush)
                audioInputDevices.push(deviceInfo);
            }
            else if (deviceInfo.kind === "videoinput")
            {
              for (let j = 0;j < videoInputDevices.length;++j)
              {
                if (videoInputDevices[j].groupId === deviceInfo.groupId)
                {
                  canPush = false;
                  break;
                }
              }
              if (canPush)
                videoInputDevices.push(deviceInfo);
            }
            else if (deviceInfo.kind === 'audiooutput')
            {
              for (let j = 0;j < audioOutputDevices.length;++j)
              {
                if (audioOutputDevices[j].groupId === deviceInfo.groupId)
                {
                  canPush = false;
                  break;
                }
              }
              if (canPush)
                audioOutputDevices.push(deviceInfo);
            }
          });
          this.setState({ audioInputDevices, videoInputDevices, audioOutputDevices });
        }
      })
      .catch(err => {
        console.log(`enumerateDevices failed, err: ${err}`);
      });
  }

  componentDidMount()
  {
    this.updateTimeTimerId = setInterval(() => this.updateDateTime(), 1000);

    this.updateDateTime();

    if (this.state && this.state.idToken)
    {
      this.getUserInfo();
      this.getUpcomingMeetings();

      let historyStartTs = TimeUtils.getPastNdayStartSecondsSinceEpoch(TimeUtils.getDaysInLastMonth());
      let historyEndTs = TimeUtils.getCurrentSecondsSinceEpoch();
      let { year: sYear, month: sMonth, date: sDate } = TimeUtils.timestampToDateObj(historyStartTs);
      let { year: eYear, month: eMonth, date: eDate } = TimeUtils.timestampToDateObj(historyEndTs);
      this.setState({
        historyStartYear: sYear, historyStartMonth: sMonth, historyStartDate: sDate,
        historyEndYear: eYear, historyEndMonth: eMonth, historyEndDate: eDate,
      });

      if (window.location.pathname === '/i')
      {
        const searchParams = new URLSearchParams(window.location.search);
        const inviteRoomId = searchParams.get('roomId');

        this.promptJoinUpcomingMeeting(inviteRoomId);
      }
    }
  }

  componentWillUnmount()
  {
    clearInterval(this.updateTimeTimerId);
  }

  render()
  {
    if (!this.state || !this.state.login)
    {
      return (<div></div>);
    }

    const joinMeetingButtonDisabled = this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING && this.state.meetingId === "";
    let enterMeetingSettingJoinButtonStyle = {...this.enterMeetingSettingJoinButtonStyle};
    let scheduleMeetingSettingScheduleButtonStyle = {...this.scheduleMeetingSettingScheduleButtonStyle};
    let userProfileMenuStyle = {...this.userProfileMenuStyle};

    if (joinMeetingButtonDisabled || this.state.isJoining) {
      enterMeetingSettingJoinButtonStyle.backgroundColor = "#b3d4ff";
      enterMeetingSettingJoinButtonStyle.border = "1px solid #b3d4ff";
    }
    if (this.state.isScheduling) {
      scheduleMeetingSettingScheduleButtonStyle.backgroundColor = "#b3d4ff";
      scheduleMeetingSettingScheduleButtonStyle.border = "1px solid #b3d4ff";
    }
    if (this.state.showUserProfileMenu) {
      userProfileMenuStyle.visibility = "visible";
    } else {
      userProfileMenuStyle.visibility = "hidden";
    }

    const meetingFunctionUpcomingTextStyle = { ...this.meetingFunctionUpcomingTextStyle };
    if (this.state.isHoverMeetingInfoUpcomingFunction) {
      meetingFunctionUpcomingTextStyle.backgroundColor = "#f5f5f5";
    }
    if (this.state.meetingInfoFunction === MeetingInfoFunction.Upcoming) {
      meetingFunctionUpcomingTextStyle.color = "#1a73e8";
    }
    const meetingFunctionHistoryTextStyle = { ...this.meetingFunctionHistoryTextStyle };
    if (this.state.isHoverMeetingInfoHistoryFunction) {
      meetingFunctionHistoryTextStyle.backgroundColor = "#f5f5f5";
    }
    if (this.state.meetingInfoFunction === MeetingInfoFunction.History) {
      meetingFunctionHistoryTextStyle.color = "#1a73e8";
    }

    return (
      <div
        className="main_page_div_style"
        onClick={() => {
          this.setState({ showUserProfileMenu: false });
        }}
      >
          <Modal title="Accept Meeting Invitation" open={this.state.showInviteModal}
            onOk={() => {
              this.acceptMeetingInvitation(this.state.inviteRoomId, this.state.inviteStartTime, this.state.inviteEndTime);
              this.setState({ showInviteModal: false });
            }}
            onCancel={() => {
              this.setState({ showInviteModal: false });
            }}
          >
            <p>Topic: {this.state.inviteTopic}</p>
            <p>Time: {TimeUtils.timestampToYearMonthDay(this.state.inviteStartTime)} {TimeUtils.timestampToHourMinute(this.state.inviteStartTime)}-{TimeUtils.timestampToHourMinute(this.state.inviteEndTime)}</p>
            <p>Organizer: {this.state.inviteCreatorName}</p>
          </Modal>

          <Modal title={`Recaps ImMessage [${this.state.recapsMeetingTopic}] [${TimeUtils.timestampToYearMonthDay(this.state.recapsMeetingStartTime)} ${TimeUtils.timestampToHourMinute(this.state.recapsMeetingStartTime)}]`}
            open={this.state.showRecapsImMessage}
            onOk={() => {
              this.setState({ showRecapsImMessage: false, recapsImMessages: [] });
            }}
            onCancel={() => {
              this.setState({ showRecapsImMessage: false, recapsImMessages: [] });
            }}
            width={"60%"}
          >
            <div className="recaps_im_message_container">
              {
                this.state.recapsImMessages && this.state.recapsImMessages.map((item, index) => (
                  <div className="recaps_im_message_item">
                    <div className="recaps_im_message_item_peer_name_and_time">
                      <div className="recaps_im_message_item_peer_name">{item.peerName}</div>
                      <div className="recaps_im_message_item_time">{TimeUtils.timestampToHourMinuteSecond(item.textTime)}</div>
                    </div>
                    <div className="recaps_im_message_item_message">
                      {item.text}
                    </div>
                  </div>
                ))
              }
              {
                !this.state.recapsImMessages &&
                <div>
                  The meeting has no im message history
                </div>
              }
            </div>
          </Modal>

          <Modal title={`Recaps Transcript Text [${this.state.recapsMeetingTopic}] [${TimeUtils.timestampToYearMonthDay(this.state.recapsMeetingStartTime)} ${TimeUtils.timestampToHourMinute(this.state.recapsMeetingStartTime)}]`}
            open={this.state.showRecapsTranscriptText}
            onOk={() => {
              this.setState({ showRecapsTranscriptText: false, recapsTranscriptTexts: [] });
            }}
            onCancel={() => {
              this.setState({ showRecapsTranscriptText: false, recapsTranscriptTexts: [] });
            }}
            width={"60%"}
          >
            <div className="recaps_transcript_text_container">
              {
                this.state.recapsTranscriptTexts && this.state.recapsTranscriptTexts.map((item, index) => (
                  <div className="recaps_transcript_text_item">
                    <div className="recaps_transcript_text_item_peer_name_and_time">
                      <div className="recaps_transcript_text_item_peer_name">{item.peerName}</div>
                      <div className="recaps_transcript_text_item_time">{TimeUtils.timestampToHourMinuteSecond(item.textTime)}</div>
                    </div>
                    <div className="recaps_transcript_text_item_message">
                      {item.text}
                    </div>
                  </div>
                ))
              }
              {
                !this.state.recapsTranscriptTexts &&
                <div>
                  The meeting has no transcript text history
                </div>
              }
            </div>
          </Modal>

          <Modal title={`Recaps Translate Text [${this.state.recapsMeetingTopic}] [${TimeUtils.timestampToYearMonthDay(this.state.recapsMeetingStartTime)} ${TimeUtils.timestampToHourMinute(this.state.recapsMeetingStartTime)}]`}
            open={this.state.showRecapsTranslateText}
            onOk={() => {
              this.setState({ showRecapsTranslateText: false, recapsTranslateTexts: [] });
            }}
            onCancel={() => {
              this.setState({ showRecapsTranslateText: false, recapsTranslateTexts: [] });
            }}
            width={"60%"}
          >
            <div className="recaps_translate_text_container">
              {
                this.state.recapsTranslateTexts && this.state.recapsTranslateTexts.map((item, index) => (
                  <div className="recaps_translate_text_item">
                    <div className="recaps_translate_text_item_peer_name_and_time">
                      <div className="recaps_translate_text_item_peer_name">{item.peerName}</div>
                      <div className="recaps_translate_text_item_time">{TimeUtils.timestampToHourMinuteSecond(item.textTime)}</div>
                    </div>
                    <div className="recaps_translate_text_item_message">
                      {item.text}
                    </div>
                  </div>
                ))
              }
              {
                !this.state.recapsTranslateTexts &&
                <div>
                  The meeting has no translate text history
                </div>
              }
            </div>
          </Modal>

          <div
            className="toolkit_div_style">
            <div
              className="function_list_div_style">
              <div
                className="meeting_div_style"
                onClick={() => {
                  this.getUpcomingMeetings();
                  this.setState({ curToolkitFunction: ToolkitFunction.MAIN_PAGE_MEETING, meetingInfoFunction: MeetingInfoFunction.Upcoming });
                }}
                style={{
                  backgroundColor: (this.state.curToolkitFunction !== ToolkitFunction.MAIN_PAGE_RECAPS) ? "#ececec" : undefined
                }}
              >
                <img className="meeting_div_img_style" src={iconNewMeeting} alt=""/>
                <p className="meeting_div_p_style">Meeting</p>
              </div>
              <div
                className="recaps_div_style"
                onClick={() => {
                  let historyStartTs = TimeUtils.getSecondsSinceEpoch(this.state.historyStartYear, this.state.historyStartMonth, this.state.historyStartDate, 0, 0, 0);
                  let historyEndTs = TimeUtils.getSecondsSinceEpoch(this.state.historyEndYear, this.state.historyEndMonth, this.state.historyEndDate, 23, 59, 59);
                  this.getHistoryMeetings(historyStartTs, historyEndTs);
                  this.setState({ curToolkitFunction: ToolkitFunction.MAIN_PAGE_RECAPS, meetingInfoFunction: MeetingInfoFunction.History });
                }}
                style={{
                  backgroundColor: (this.state.curToolkitFunction === ToolkitFunction.MAIN_PAGE_RECAPS) ? "#ececec" : undefined
                }}
              >
                <img className="recaps_div_img_style" src={iconRecaps} alt=""/>
                <p className="recaps_div_p_style">Recaps</p>
              </div>
              {/* {
                this.state.isPro === false && (
                  <div
                    className="upgrade_div_style"
                    onClick={() => {
                      window.open('https://aimeet.lemonsqueezy.com/buy/dfcf2254-90c1-419a-95f5-a9230787770a');
                    }}
                  >
                    Upgrade
                  </div>
                )
              }
              {
                this.state.isPro === true && (
                  <div
                    className="pro_div_style"
                    onClick={() => {
                      this.setState({ showProSubscription: true });
                    }}
                  >
                    Aimeet Pro
                  </div>
                )
              }
              {
                this.state.isPro === true && (
                  <Modal title="Subscription" open={this.state.showProSubscription}
                    onOk={() => {
                      this.setState({ showProSubscription: false });
                    }}
                    onCancel={() => {
                      this.setState({ showProSubscription: false });
                    }}
                    footer={[
                      <Button key="proSubscriptionManage">
                        Manage
                      </Button>
                    ]}
                  >
                    <div className="pro_subscription_manager">
                      <img className="pro_subscription_manager_img" src={iconProStar}></img>
                      <p className="pro_subscription_manager_text">Aimeet Pro</p>
                      <p className="pro_subscription_manager_exp_date">Expire at: {TimeUtils.timestampToYearMonthDay(this.state.proExpTime)}</p>
                    </div>
                  </Modal>
                )
              } */}
            </div>
          </div>

          <div
            className="user_main_page_div_style">
            <div
              className="top_bar_div_style">
              <div
                className="product_name_div_style"
                onClick={() => {
                  this.getUpcomingMeetings();
                  this.setState({ curToolkitFunction: ToolkitFunction.MAIN_PAGE_MEETING, meetingInfoFunction: MeetingInfoFunction.Upcoming });
                }}
              >
                <img className="product_name_img_style" src={aimeetpng} alt=""/>
              </div>

              <div className="date_time_div_style">
                {this.state.dateTime}
              </div>

              <div
                className="user_profile_div_style"
                onClick={(event) => {
                  this.setState({ showUserProfileMenu: !this.state.showUserProfileMenu });
                  event.stopPropagation();
                }}
              >
                <div
                  className="user_profile_img_div_style">
                  <img className="user_profile_img_style" src={this.state.picture} alt=""/>
                </div>
              </div>

              <div
                style={userProfileMenuStyle}
              >
                <div
                  className="user_profile_menu_account_info_style"
                  onClick={(event) => {
                    event.stopPropagation();
                    // do nothing
                  }}
                >
                  { this.state.displayName && (<div className="user_profile_menu_account_info_name_style">{this.state.displayName}</div>) }
                  { this.state.email && (<div className="user_profile_menu_account_info_email_style">{this.state.email}</div>) }
                </div>
                <hr className="user_profile_menu_separator_style"></hr>
                <div
                  className="user_profile_menu_survey_style"
                  onClick={() => {
                    window.open('https://forms.gle/2F98S5aNKgCw2rTH7')
                  }}
                >
                  <img className="user_profile_menu_survey_image_style" src={iconUserSurvey} alt=""/>
                  <p className="user_profile_menu_survey_p_style">User Survey</p>
                </div>
                <div
                  className="user_profile_menu_logout_style"
                  onClick={() => {
                    jsCookie.remove(ID_TOKEN_COOKIE, { domain: DomainUtils.getSecondLevelDomain() });
                    this.auth0.logout({ logoutParams: { returnTo: window.location.origin } });
                    window.location.replace(WWW_URL);
                  }}
                >
                  <img className="user_profile_menu_logout_image_style" src={iconLogout} alt=""/>
                  <p className="user_profile_menu_logout_p_style">Log Out</p>
                </div>
              </div>
            </div>

            <div
              className="middle_div_style"
            >
            {
              (this.state.curToolkitFunction === ToolkitFunction.MAIN_PAGE_MEETING) &&
              <div className="meeting_functions_div_style">
                <div className="meeting_meeting_functions_items">
                  <div
                    className="meeting_new_meeting_div_style"
                    onClick={() => {
                      this.setState({ curToolkitFunction: ToolkitFunction.NEW_MEETING });
                      this.enumerateDevices();
                    }}
                  >
                    <img className="meeting_new_meeting_div_img_style" src={iconNewMeeting} alt=""/>
                    <p className="meeting_new_meeting_div_p_style">New Meeting</p>
                  </div>
                  <div
                    className="meeting_join_meeting_div_style"
                    onClick={() => {
                      this.setState({ curToolkitFunction: ToolkitFunction.JOIN_MEETING, meetingId: '' });
                      this.enumerateDevices();
                    }}
                  >
                    <img className="meeting_join_meeting_div_img_style" src={iconJoinMeeting} alt=""/>
                    <p className="meeting_join_meeting_div_p_style">Join Meeting</p>
                  </div>
                  <div
                    className="meeting_schedule_meeting_div_style"
                    onClick={() => {
                      let { year, month, date, hour, minute } = TimeUtils.getNextNearestHourTime();

                      this.setState({
                        curToolkitFunction: ToolkitFunction.SCHEDULE_MEETING,
                        scheduleYear: year,
                        scheduleMonth: month,
                        scheduleDate: date,
                        scheduleHour: hour,
                        scheduleMinute: minute
                      });
                    }}
                  >
                    <img className="meeting_schedule_meeting_div_img_style" src={iconScheduleMeeting} alt=""/>
                    <p className="meeting_schedule_meeting_div_p_style">Schedule Meeting</p>
                  </div>
                </div>
                <div className="meeting_info_functions_items">
                  <div style={meetingFunctionUpcomingTextStyle}
                    onMouseEnter={() => this.setState({ isHoverMeetingInfoUpcomingFunction: true })}
                    onMouseLeave={() => this.setState({ isHoverMeetingInfoUpcomingFunction: false })}
                    onClick={() => {
                      this.getUpcomingMeetings();
                      this.setState({ meetingInfoFunction: MeetingInfoFunction.Upcoming });
                    }}
                  >
                    Upcoming
                  </div>
                </div>
                {
                  (this.state.meetingInfoFunction === MeetingInfoFunction.Upcoming) &&
                  <div className="upcoming_meetings_div">
                    {
                      this.state.isLoadingUpcoming && 
                      <div className="upcoming_meetings_loading_div">
                        <Spin size="large"/>
                      </div>
                    }
                    {
                      !this.state.isLoadingUpcoming && !this.state.upcomingMeetings &&
                      <div className="no_upcoming_meetings_div">
                        <img className="no_upcoming_meetings_rest_img" src={iconRest}></img>
                        <p className="no_upcoming_meetings_text">No Upcoming Meeting</p>
                      </div>
                    }
                    {
                      !this.state.isLoadingUpcoming && this.state.upcomingMeetings && this.state.upcomingMeetings.map((item, index) => (
                        !item.cancelled &&
                        <div className="upcoming_meeting_item" key={index}>
                          <div className="upcoming_meeting_information">
                            <div className="upcoming_meeting_time">
                              { (TimeUtils.getCurrentDate() === TimeUtils.timestampToMonthDay(item.startTime) ? "Today " : "") + TimeUtils.timestampToMonthDay(item.startTime) +
                                  " " + TimeUtils.timestampToHourMinute(item.startTime) + " - " + TimeUtils.timestampToHourMinute(item.endTime)
                              }
                            </div>
                            <div className="upcoming_meeting_topic">{item.topic}</div>
                            <div className="upcoming_meeting_organizer_and_meeting_id">
                              <p className="upcoming_meeting_organizer">{ "Organizer: " + item.creatorName }</p>
                              <p className="upcoming_meeting_meeting_id">{ "ID: " + item.roomId}</p>
                            </div>
                          </div>
                          <div className="upcoming_meeting_link">
                            <Popover title="Invitation link" trigger="hover"
                              content={(
                                <div className="invitation_div_style">
                                  <div className="invitaion_link">{`https://app.aimeet.cc/i?roomId=${item.roomId}`}</div>
                                  <img className="invitaion_link_copy" src={iconCopy} alt="" onClick={() => {
                                    navigator.clipboard.writeText(`https://app.aimeet.cc/i?roomId=${item.roomId}`)
                                      .then(() => {
                                        message.info('Invitation link copied success');
                                      })
                                      .catch(err => {
                                        message.error('Invitation link copied failed, please try again');
                                      });
                                  }}/>
                                </div>
                              )}>
                              <img className="upcoming_meeting_link_img" src={iconLink} alt=""/>
                            </Popover>
                          </div>
                          <div className="upcoming_meeting_join"
                            onClick={() => {
                              this.setState({ curToolkitFunction: ToolkitFunction.JOIN_MEETING, meetingId: item.roomId });
                              this.enumerateDevices();
                            }}
                          >
                            Join
                          </div>
                          <div className="upcoming_meeting_cancel"
                            onClick={() => {
                              this.cancelScheduleMeeting(item.roomId, item.startTime, item.endTime);
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            }

            {
              (this.state.curToolkitFunction === ToolkitFunction.NEW_MEETING ||
                this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING ||
                this.state.curToolkitFunction === ToolkitFunction.SCHEDULE_MEETING) &&
              <div className="enter_meeting_setting_div_style">
                <div className="enter_meeting_setting_title_div_style">
                  <p className="enter_meeting_setting_title_style">
                    { this.state.curToolkitFunction }
                  </p>
                </div>

                {
                  (this.state.curToolkitFunction === ToolkitFunction.NEW_MEETING ||
                    this.state.curToolkitFunction === ToolkitFunction.SCHEDULE_MEETING) &&
                  (
                    <div className="enter_meeting_setting_meeting_topic_div_style">
                      <p className="enter_meeting_setting_meeting_topic_p_style">
                        Topic
                      </p>
                      <input className="enter_meeting_setting_meeting_topic_input_style"
                        value={this.state.meetingTopic}
                        placeholder={'Meeting Topic'}
                        onInput={(event) => {
                          this.setState({ meetingTopic: event.target.value });
                        }}
                      />
                    </div>
                  )
                }

                {
                  this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING &&
                  (
                    <div className="enter_meeting_setting_meeting_id_div_style">
                      <p className="enter_meeting_setting_meeting_id_p_style">
                        MeetingId
                      </p>
                      <input className="enter_meeting_setting_meeting_id_input_style"
                        placeholder="Enter meeting ID or paster invite URL"
                        value={this.state.meetingId}
                        onInput={(event) => {
                          this.setState({ meetingId: event.target.value });
                        }}
                      />
                    </div>
                  )
                }

                {
                  (this.state.curToolkitFunction === ToolkitFunction.NEW_MEETING ||
                    this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING) &&
                    (
                      <div className="enter_meeting_setting_meeting_name_div_style">
                        <p className="enter_meeting_setting_meeting_name_p_style">DisplayName</p>
                        <input className="enter_meeting_setting_meeting_name_input_style"
                          placeholder="Your displayName in meeting"
                          value={this.state.displayName}
                          onInput={(event) => {
                            this.setState({ displayName: event.target.value });
                          }}
                        >
                        </input>
                      </div>
                    )
                }

                {
                  (this.state.curToolkitFunction === ToolkitFunction.NEW_MEETING ||
                    this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING) &&
                    (
                      <div className="enter_meeting_setting_meeting_setting_div_style">
                        <div className="enter_meeting_setting_meeting_setting_reminder_div_style">
                          Audio & Video Settings
                        </div>
                        <div className="enter_meeting_setting_audio_setting_div_style">
                          <div className="enter_meeting_setting_audio_status_div_style"
                            onClick={() => {
                              if (this.state.micStatus === MicStatus.MicON)
                                this.setState({ micStatus: MicStatus.MicOFF });
                              else
                                this.setState({ micStatus: MicStatus.MicON});
                            }}
                          >
                            <img className="enter_meeting_setting_audio_status_img_style"
                              src={this.state.micStatus === MicStatus.MicON ? iconMicOn : iconMicOff}
                              alt=""
                            />
                            <p className="enter_meeting_setting_audio_status_p_style">
                              {this.state.micStatus}
                            </p>
                          </div>
                          <select className="enter_meeting_setting_audio_devices_style"
                            disabled={this.state.micStatus === MicStatus.MicOFF}
                            onChange={(e) =>
                            {
                              this.setState({ audioInputSelectedIdx: e.target.selectedIndex});
                            }}
                          >
                            {
                              this.state.audioInputDevices && this.state.audioInputDevices.map((device, index) => (
                                <option key={index}>{device.label}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="enter_meeting_setting_video_setting_div_style">
                          <div className="enter_meeting_setting_video_status_div_style"
                            onClick={() => {
                              if (this.state.videoStatus === VideoStatus.VideoON)
                                this.setState({ videoStatus: VideoStatus.VideoOFF })
                              else
                                this.setState({ videoStatus: VideoStatus.VideoON })
                            }}
                          >
                            <img className="enter_meeting_setting_video_status_img_style"
                              src={this.state.videoStatus === VideoStatus.VideoON ? iconWebcamOn : iconWebcamOff}
                              alt=""
                            />
                            <p className="enter_meeting_setting_video_status_p_style">{this.state.videoStatus}</p>
                          </div>
                          <select className="enter_meeting_setting_video_devices_style"
                            disabled={this.state.videoStatus === VideoStatus.VideoOFF}
                            onChange={(e) =>
                            {
                              this.setState({ videoInputSelectedIdx: e.target.selectedIndex});
                            }}
                          >
                            {
                              this.state.videoInputDevices && this.state.videoInputDevices.map((device, index) => (
                                <option key={index}>{device.label}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>
                    )
                }

                {
                  (this.state.curToolkitFunction === ToolkitFunction.NEW_MEETING ||
                    this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING) &&
                    (
                      <div className="enter_meeting_setting_join_div_style">
                        <button
                          style={enterMeetingSettingJoinButtonStyle}
                          disabled={joinMeetingButtonDisabled || this.state.isJoining}
                          onClick={() => {
                            const micLabel = this.state.audioInputSelectedIdx < this.state.audioInputDevices.length ?
                              this.state.audioInputDevices[this.state.audioInputSelectedIdx].label : '';
                            const camLabel = this.state.videoInputSelectedIdx < this.state.videoInputDevices.length ?
                              this.state.videoInputDevices[this.state.videoInputSelectedIdx].label : '';
                            const speakerLabel = this.state.audioOutputSelectedIdx < this.state.audioOutputDevices.length ?
                              this.state.audioOutputDevices[this.state.audioOutputSelectedIdx].label : '';
                            const displayName = this.state.displayName;

                            jsCookie.set(USER_SETTING_COOKIE,
                            {
                              micOn: this.state.micStatus === MicStatus.MicON,
                              videoOn: this.state.videoStatus === VideoStatus.VideoON,
                              peerId: this.state.peerId,
                              displayName,
                              micLabel,
                              camLabel,
                              speakerLabel
                            });
                            if (this.state.curToolkitFunction === ToolkitFunction.NEW_MEETING)
                            {
                              fetch('https://signal.aimeet.cc/api/create_room', {
                                method: 'POST',
                                cache: "no-cache",
                                headers: {
                                  'Content-Type': 'application/json',
                                  'Accept': '*/*',
                                },
                                body: JSON.stringify({
                                  appId: 3,
                                  token: this.state.rawToken,
                                  topic: this.state.meetingTopic,
                                  username: this.state.displayName,
                                })
                              })
                              .then(response => {
                                if (!response.ok) {
                                  throw new Error('Network response was not ok', response.statusText);
                                }
                                return response.json();
                              })
                              .then(data => {
                                this.setState({ isJoining: false });
                                if (data.data && data.data.roomId) {
                                  window.location.replace(`https://app.aimeet.cc/r?roomId=${data.data.roomId}`);
                                } else {
                                  message.error('Server error, please try again');
                                }
                              })
                              .catch(error => {
                                message.error('Network error, please try again');
                                this.setState({ isJoining: false });
                              });
                              this.setState({ isJoining: true });
                            }
                            else if (this.state.curToolkitFunction === ToolkitFunction.JOIN_MEETING)
                            {
                              window.location.replace(`https://app.aimeet.cc/r?roomId=${this.state.meetingId}`);
                            }
                          }}
                        >
                            Join
                        </button>
                      </div>
                    )
                }

                {
                  this.state.curToolkitFunction === ToolkitFunction.SCHEDULE_MEETING &&
                  (
                    <div className="schedule_meeting_time_setting_div_style">
                      <div className="schedule_meeting_time_setting_reminder_div_style">
                        Time Setting
                      </div>
                      <div className="schedule_meeting_when_time_div_style">
                        <p className="schedule_meeting_when_time_p_style">When</p>
                        <ConfigProvider
                          theme={{
                            components: {
                              DatePicker: {
                                activeBorderColor: "#000",
                                hoverBorderColor: "#000",
                                addonBg: "#767676",
                                colorBorder: "#767676",
                                inputFontSizeLG: 20
                              },
                              TimePicker: {
                                activeBorderColor: "#000",
                                hoverBorderColor: "#000",
                                addonBg: "#767676",
                                colorBorder: "#767676"
                              }
                            }
                          }}
                        >
                          <DatePicker className="schedule_meeting_when_time_date_pick_style" size="large"
                            defaultValue={dayjs(String(this.state.scheduleYear) + "-" + String(this.state.scheduleMonth).padStart(2, '0') + "-" + String(this.state.scheduleDate).padStart(2, 0))}
                            onChange={(value) => {
                              if (value) {
                                this.setState({
                                  scheduleYear  : value.year(),
                                  scheduleMonth : value.month()+1,
                                  scheduleDate  : value.date()
                                });
                              } else {
                                this.setState({ scheduleYear : -1, scheduleMonth : -1, scheduleDate : -1 });
                              }
                            }}
                          />
                          <TimePicker className="schedule_meeting_when_time_time_pick_style" format="HH:mm" size="large" minuteStep="5" needConfirm="false"
                            defaultValue={dayjs(String(this.state.scheduleHour).padStart(2, '0') + ":" + String(this.state.scheduleMinute).padStart(2, '0'), 'HH:mm')}
                            onChange={(value) => {
                              if (value) {
                                this.setState({
                                  scheduleHour   : value.hour(),
                                  scheduleMinute : value.minute()
                                });
                              } else {
                                this.setState({ scheduleHour : -1, scheduleMinute : -1 });
                              }
                            }}
                          />
                        </ConfigProvider>

                        <select className="schedule_meeting_timezone_select_zone_style"
                          value={this.state.scheduleTimezoneOffset}
                          onChange={(event) => {
                            this.setState({ scheduleTimezoneOffset: event.target.value });
                          }}
                        >
                          <option value={-12}>GMT-12:00</option>
                          <option value={-11}>GMT-11:00</option>
                          <option value={-10}>GMT-10:00</option>
                          <option value={-9}>GMT-9:00</option>
                          <option value={-8}>GMT-8:00</option>
                          <option value={-7}>GMT-7:00</option>
                          <option value={-6}>GMT-6:00</option>
                          <option value={-5}>GMT-5:00</option>
                          <option value={-4}>GMT-4:00</option>
                          <option value={-3}>GMT-3:00</option>
                          <option value={-2}>GMT-2:00</option>
                          <option value={-1}>GMT-1:00</option>
                          <option value={0}>GMT+0:00</option>
                          <option value={1}>GMT+1:00</option>
                          <option value={2}>GMT+2:00</option>
                          <option value={3}>GMT+3:00</option>
                          <option value={4}>GMT+4:00</option>
                          <option value={5}>GMT+5:00</option>
                          <option value={6}>GMT+6:00</option>
                          <option value={7}>GMT+7:00</option>
                          <option value={8}>GMT+8:00</option>
                          <option value={9}>GMT+9:00</option>
                          <option value={10}>GMT+10:00</option>
                          <option value={11}>GMT+11:00</option>
                          <option value={12}>GMT+12:00</option>
                        </select>
                      </div>
                      <div className="schedule_meeting_duration_div_style">
                        <p className="schedule_meeting_duration_p_duration_style">Duration</p>
                        <select className="schedule_meeting_duration_select_hour_style"
                          value={this.state.scheduleDurationHour}
                          onChange={(event) => {
                            this.setState({ scheduleDurationHour : Number(event.target.value) });
                          }}
                        >
                          <option value={0}>0</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                          <option value={11}>11</option>
                          <option value={12}>12</option>
                          <option value={13}>13</option>
                          <option value={14}>14</option>
                          <option value={15}>15</option>
                          <option value={16}>16</option>
                          <option value={17}>17</option>
                          <option value={18}>18</option>
                          <option value={19}>19</option>
                          <option value={20}>20</option>
                          <option value={21}>21</option>
                          <option value={22}>22</option>
                          <option value={23}>23</option>
                          <option value={24}>24</option>
                        </select>
                        <p className="schedule_meeting_duration_p_hour_style">hour</p>
                        <select className="schedule_meeting_duration_select_min_style"
                          value={this.state.scheduleDurationMinute}
                          onChange={(event) => {
                            this.setState({ scheduleDurationMinute : Number(event.target.value) });
                          }}
                        >
                          <option value={0}>0</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                        </select>
                        <p className="schedule_meeting_duration_p_min_style">min</p>
                      </div>
                    </div>
                  )
                }

                {
                  this.state.curToolkitFunction === ToolkitFunction.SCHEDULE_MEETING &&
                  (
                    <div className="schedule_meeting_schedule_div_style">
                      <button
                        style={scheduleMeetingSettingScheduleButtonStyle}
                        onClick={() => {
                          this.scheduleMeeting();
                        }}
                      >
                        Schedule
                      </button>
                    </div>
                  )
                }
              </div>
            }

            {
              (this.state.curToolkitFunction === ToolkitFunction.MAIN_PAGE_RECAPS) &&
              <div className="recaps_functions_div_style">
                <div className="recaps_recaps_functions_items">
                  <DatePicker className="recaps_start_date_pick_style" size="large"
                    defaultValue={dayjs(String(this.state.historyStartYear) + "-" + String(this.state.historyStartMonth).padStart(2, '0') + "-" + String(this.state.historyStartDate).padStart(2, '0'))}
                    onChange={(value) => {
                      if (value) {
                        this.setState({ historyStartYear: value.year(), historyStartMonth: value.month() + 1, historyStartDate: value.date() });

                        if (this.state.historyEndYear !== -1 && this.state.historyEndMonth !== -1 && this.state.historyEndDate !== -1)
                        {
                          let historyStartTs = TimeUtils.getSecondsSinceEpoch(value.year(), value.month() + 1, value.date(), 0, 0, 0);
                          let historyEndTs = TimeUtils.getSecondsSinceEpoch(this.state.historyEndYear, this.state.historyEndMonth, this.state.historyEndDate, 23, 59, 59);
                          this.getHistoryMeetings(historyStartTs, historyEndTs);
                        }
                      } else {
                        this.setState({ historyStartYear: -1, historyStartMonth: -1, historyStartDate: -1 });
                      }
                    }}
                  />
                  <p className="recaps_to_text_style">to</p>
                  <DatePicker className="recaps_end_date_pick_style" size="large"
                    defaultValue={dayjs(String(this.state.historyEndYear) + "-" + String(this.state.historyEndMonth).padStart(2, '0') + "-" + String(this.state.historyEndDate).padStart(2, '0'))}
                    onChange={(value) => {
                      if (value) {
                        this.setState({ historyEndYear: value.year(), historyEndMonth: value.month() + 1, historyEndDate: value.date() });

                        if (this.state.historyStartYear !== -1 && this.state.historyStartMonth !== -1 && this.state.historyStartDate !== -1)
                        {
                            let historyStartTs = TimeUtils.getSecondsSinceEpoch(this.state.historyStartYear, this.state.historyStartMonth, this.state.historyStartDate, 0, 0, 0);
                            let historyEndTs = TimeUtils.getSecondsSinceEpoch(value.year(), value.month() + 1, value.date(), 23, 59, 59);
                            this.getHistoryMeetings(historyStartTs, historyEndTs);
                        }
                      } else {
                        this.setState({ historyEndYear: -1, historyEndMonth: -1, historyEndDate: -1 });
                      }
                    }}
                  />
                </div>
                <div className="recaps_info_functions_items">
                  <div style={meetingFunctionHistoryTextStyle}
                    onMouseEnter={() => this.setState({ isHoverMeetingInfoHistoryFunction: true })}
                    onMouseLeave={() => this.setState({ isHoverMeetingInfoHistoryFunction: false })}
                    onClick={() => {
                      this.setState({ meetingInfoFunction: MeetingInfoFunction.History });
                    }}
                  >
                    History
                  </div>
                </div>
                {
                  (this.state.meetingInfoFunction === MeetingInfoFunction.History) &&
                  <div className="history_meetings_div">
                    {
                      this.state.isLoadingHistory && 
                      <div className="history_meetings_loading_div">
                        <Spin size="large"/>
                      </div>
                    }
                    {
                      !this.state.isLoadingHistory && !this.state.historyMeetings &&
                      <div className="no_history_meetings_div">
                        <img className="no_history_meetings_img" src={iconHistoryRecord} alt=""></img>
                        <p className="no_history_meetings_text">No History Meeting</p>
                      </div>
                    }
                    {
                      !this.state.isLoadingHistory && this.state.historyMeetings && this.state.historyMeetings.map((item, index) => (
                        <div className="history_meeting_item" key={index}>
                          <div className="history_meeting_information">
                            <div className="history_meeting_time">
                              {
                                TimeUtils.timestampToDayOfWeek(item.peerJoinTime) + " " + TimeUtils.timestampToMonthDay(item.peerJoinTime) + " " +
                                TimeUtils.timestampToHourMinute(item.peerJoinTime) + " - " + TimeUtils.timestampToHourMinute(item.peerLeaveTime)
                              }
                            </div>
                            <div className="history_meeting_topic">{item.topic}</div>
                            <div className="history_meeting_organizer_and_meeting_id">
                              <p className="history_meeting_organizer">{ "Organizer: " + item.roomCreatorName }</p>
                              <p className="history_meeting_meeting_id">{ "ID: " + item.roomId}</p>
                            </div>
                          </div>
                          <div className="history_recaps_im_message"
                            data-tip="Recaps Meeting IM Message"
                            onClick={() => {
                              this.setState({ showRecapsImMessage: true, recapsMeetingTopic: item.topic, recapsMeetingStartTime: item.roomStartTime });
                              this.getRoomImMessage(item.roomId, item.roomStartTime, item.peerJoinTime, item.peerLeaveTime);
                            }}
                          >
                            <img className="history_recaps_im_message_img" src={iconRecapsImMessage} alt=""/>
                          </div>
                          <div className="history_recaps_transcript_text"
                            data-tip={"Recaps Meeting Transcript Text"}
                            onClick={() => {
                              this.setState({ showRecapsTranscriptText: true, recapsMeetingTopic: item.topic, recapsMeetingStartTime: item.roomStartTime });
                              this.getRoomTranscriptText(item.roomId, item.roomStartTime, item.peerJoinTime, item.peerLeaveTime);
                            }}
                          >
                            <img className="history_recaps_transcript_text_img" src={iconRecapsTranscriptText} alt=""/>
                          </div>
                          <div className="history_recaps_translate_text"
                            data-tip={"Recaps Meeting Translate Text"}
                            onClick={() => {
                              this.setState({ showRecapsTranslateText: true, recapsMeetingTopic: item.topic, recapsMeetingStartTime: item.roomStartTime });
                              this.getRoomTranslateText(item.roomId, item.roomStartTime, item.peerJoinTime, item.peerLeaveTime);
                            }}
                          >
                            <img className="history_recaps_translate_text_img" src={iconRecapsTranslateText} alt=""/>
                          </div>
                          <ReactTooltip type='light' effect='solid'/>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            }
            </div>

            <div className="bottom_div_style">
              <span>You can always connect with others and communicate effectively</span>
              <span>Fill out the <a href="https://forms.gle/2F98S5aNKgCw2rTH7" target="_blank" style={{"textDecoration": "none"}}>survey</a> to help Aimeet become better</span>
            </div>
          </div>
      </div>
    )
  }
}

export default withAuth0(MainPage);
